import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";
import { UserContext } from "../contexts";
import { TOKEN } from "../queries";
import { LOGOUT } from "../mutations";

const Sidebar = () => {

  const [,setUser] = useContext(UserContext);
  const history = useHistory();
  const client = useApolloClient();

  const [logout,] = useMutation(LOGOUT, {
    onCompleted: () => {
      setUser(false);
      client.cache.writeQuery({query: TOKEN, data: {accessToken: null}});
      history.push("/");
    }
  });

  return (
    <div className="px-4 py-6 border-gray-100 border-r bg-blue-50">
      <Link to="/" className="cursor-pointer text-center mb-6 block font-bold text-purple-900">Admin</Link>
      <div className="grid gap-4 font-light text-gray-800">
        <Link to="/list/" className="cursor-pointer">Waiting List</Link>
        <Link to="/users/" className="cursor-pointer">User Growth</Link>
        <div onClick={logout} className="cursor-pointer fixed bottom-3 text-center text-xs text-gray-400 left-0" style={{width: 150}}>Log Out</div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  
};

export default Sidebar;