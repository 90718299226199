import React from "react";
import PropTypes from "prop-types";
import Div100vh from "react-div-100vh";
import colors from "tailwindcss/colors";
import Sidebar from "../components/Sidebar";
import MoonLoader from "react-spinners/MoonLoader";

const Base = props => {

  const { className, loading } = props;

  return (
    <Div100vh className="grid grid-cols-sidebar">
      <Sidebar />  
      <main className={`${loading ? "flex justify-center items-center" : ""} ${className} `}>
        {loading ? <MoonLoader css="display: block; margin: auto;" size="70px" color={colors.blue[200]} /> : props.children}
      </main>
    </Div100vh>
  )
};

Base.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool
};

export default Base;