import React from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import Base from "./Base";
import colors from "tailwindcss/colors";
import { USER_GROWTH } from "../queries";

const UserGrowthPage = () => {

  const { loading, data } = useQuery(USER_GROWTH);

  function formatter() {
    const emails = this.series.chart.userOptions.emails;
    const dt = moment(this.point.x).format("dddd D MMMM, YYYY (HH:mm)");
    const email = this.point.index > emails.length ? "" : emails[this.point.index - 1];
    return (
      `<div style="font-size: 10px">${dt}</div><br>
      <div style="font-size: 16px">${email}</div>`
    )
  }

  if (loading) return <Base loading={true} />

  const creations = data.accountCreations.map(creation => [creation.timestamp, 1, creation.email]);
  const deletions = data.accountDeletions.map(deletion => [deletion.timestamp, -1, deletion.email]);
  const chronology = creations.concat(deletions);
  chronology.sort((a, b) => a[0] - b[0]);

  const series = [{
    step: "left",
    color: colors.green[700],
    data: chronology.map((point, index) => [
      point[0] * 1000,
      chronology.slice(0, index + 1).reduce((prev, curr) => prev + curr[1], 0)
    ])
  }]
  if (series[0].data.length) series[0].data.splice(0, 0, [series[0].data[0][0], 0])

  const userCount = series[0].data.length ? series[0].data[series[0].data.length - 1][1] : 0;

  const options = {
    credits: {enabled: false},
    xAxis: {type: "datetime"},
    yAxis: {title: ""},
    title: {text: `${userCount} User Account${userCount === 1 ? "" : "s"}`},
    legend: {enabled: false},
    series,
    emails: chronology.map(p => p[2]),
    tooltip: {formatter},
  }

  return (
    <Base>
      <div className="h-full" style={{width: "calc(100vw - 150px)"}}>
        <HighchartsReact
          highcharts={Highcharts}
          containerProps={{ style: { height: "100vh" }}}
          options={options}
        />
      </div>
    </Base>
  );
};

UserGrowthPage.propTypes = {
  
};

export default UserGrowthPage;