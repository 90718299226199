import React, { useState, useRef } from "react";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import Base from "./Base";
import colors from "tailwindcss/colors";
import { WAITING_LIST } from "../queries";
import { TOGGLE_WAITING_USER } from "../mutations";

const WaitingListPage = () => {

  const { loading, data } = useQuery(WAITING_LIST);
  const [email, setEmail] = useState(null);
  const boxRef = useRef(null);

  const dismiss = e => {
    if (!boxRef.current.contains(e.target)) {
      setEmail(null);
    }
  }

  const [toggle,] = useMutation(TOGGLE_WAITING_USER, {
    refetchQueries: [{query: WAITING_LIST}],
    onCompleted: () => setEmail(null)
  });

  if (loading) return <Base loading={true} />

  const users = [...data.waitingUsers];
  users.reverse();

  function formatter() {
    const emails = this.series.chart.userOptions.emails;
    const dt = moment(this.point.x).format("dddd D MMMM, YYYY (HH:mm)");
    const email = this.point.index > emails.length ? "" : emails[this.point.index - 1].email;
    return (
      `<div style="font-size: 10px">${dt}</div><br>
      <div style="font-size: 16px">${email}</div>`
    )
  }

  const options = {
    credits: {enabled: false},
    xAxis: {type: "datetime"},
    yAxis: {title: ""},
    title: {text: `${users.length} ${users.length === 1 ? "Person" : "People"} Waiting`},
    legend: {enabled: false},
    emails: data.waitingUsers,
    tooltip: {formatter},
    series: data.waitingUsers.length ? [{
      data: [[data.waitingUsers[0].created * 1000, 0]].concat(
        data.waitingUsers.map((user, i) => [user.created * 1000, i + 1])
      ).concat([[new Date().getTime(), data.waitingUsers.length]]),
      step: "left",
      color: colors.green[300]
    }] : []
  }

  return (
    <Base className="grid grid-cols-list">
      <div className="h-full" style={{width: "calc(100vw - 350px)"}}>
        <HighchartsReact
          highcharts={Highcharts}
          containerProps={{ style: { height: "100vh" }}}
          options={options}
        />
      </div>
      <div className="border-gray-100 border-l py-3 font-light">
        {users.map(user => (
          <div key={user.id} className={`mb-2 px-3 cursor-pointer hover:bg-gray-50 ${user.canTest ? "text-green-600" : ""} ${user.approved ? "opacity-30" : ""}`} onClick={() => setEmail(user.email)}>
            <div className={`text-sm`}>{user.email}</div>
            <time className="text-xs">{moment(user.created * 1000).format("HH:mm - DD MMMM YYYY")}</time>
          </div>
        ))}

        <div onClick={dismiss} className={`fixed w-full h-full bg-gray-400 bg-opacity-70 left-0 top-0 flex justify-center items-center ${email ? "block" : "hidden"}`}>
          <div className="white-box w-64 py-5" ref={boxRef}>
            <div className="text-center mb-2">{email}</div>
            <div className="text-center text-xs text-gray-600 mb-5">{email && !users.filter(u => u.email === email)[0].approved && "This will email them."}</div>
            <button onClick={() => toggle({variables: {email}})} className="btn block m-auto">
              {email && users.filter(u => u.email === email)[0].approved ? "Unapprove" : "Approve"}
            </button>
          </div>
        </div>
      </div>
    </Base>
  );
};

WaitingListPage.propTypes = {
  
};

export default WaitingListPage;