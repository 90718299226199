import React, { useState, useContext } from "react";
import Div100vh from "react-div-100vh";
import { useApolloClient, useMutation } from "@apollo/client";
import { LOGIN } from "../mutations";
import { UserContext } from "../contexts";
import { TOKEN } from "../queries";

const LoginPage = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [,setUser] = useContext(UserContext);
  const client = useApolloClient();

  const [login,] = useMutation(LOGIN, {
    onCompleted: data => {
      setUser(data.login.user);
      client.cache.writeQuery({
        query: TOKEN, data: {accessToken: data.login.accessToken}
      });
    },
    onError: () => setError(true)
  });

  const formSubmit = e => {
    e.preventDefault();
    setError(false);
    login({variables: {email, password}});
  }

  return (
    <Div100vh className="flex justify-center items-center bg-gray-50">
      <form onSubmit={formSubmit} className="white-box py-5 pt-14 px-4 w-full h-full sm:max-w-sm sm:h-auto sm:pt-5 relative">
        {error && <div className="text-xs text-center absolute text-red-700 top-4 w-full">Could not sign in</div> }
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          autoComplete="email"
          required
        />
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          autoComplete="password"
          required
        />
        <button type="submit" className="btn">Sign In</button>
      </form>
    </Div100vh>
  );
};

LoginPage.propTypes = {
  
};

export default LoginPage;