import React from "react";
import Base from "./Base";
import analytics from "../images/analytics.svg";

const HomePage = () => {

  return (
    <Base className="flex items-center justify-center">
      <img src={analytics} alt="" className="block w-2/4 max-w-screen-sm opacity-50" />
    </Base>
  );
};

HomePage.propTypes = {
  
};

export default HomePage;