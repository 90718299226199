import React, { useState } from "react";
import { Route } from "react-router";
import { BrowserRouter, Switch } from "react-router-dom";
import { ApolloProvider, useQuery } from "@apollo/client";
import Div100vh from "react-div-100vh";
import PropagateLoader from "react-spinners/PropagateLoader";
import { makeClient } from "../api";
import { TOKEN, USER } from "../queries";
import { UserContext } from "../contexts";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import WaitingListPage from "../pages/WaitingListPage";
import colors from "tailwindcss/colors";
import UserGrowthPage from "../pages/UserGrowthPage";

const client = makeClient();

const App = () => {

  // Keep track of whether there is a logged in user
  const [user, setUser] = useState(null);

  // Send request for access token
  const tokenQuery = useQuery(TOKEN, {client, pollInterval: 1000 * 60 * 2});

  // If the token query has fired at least once, and log-in status is unknown,
  // try to get user
  useQuery(USER, {
    client,
    skip: tokenQuery.loading || user !== null,
    onCompleted: data => setUser(data.user),
    onError: () => setUser(false)
  })

  // While token request loading, or log-in status is unknown, show loading page
  if (tokenQuery.loading || user === null) {
    return (
      <Div100vh className="flex justify-center items-center">
        <PropagateLoader color={colors.blue[500]} />
      </Div100vh>
    )
  }

  // Otherwise return the normal app with the log-in status set
  return (
    <ApolloProvider client={client}>
      <UserContext.Provider value={[user, setUser]}>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact>
              {user ? <HomePage /> : <LoginPage />}
            </Route>
            <Route path="/list/" exact>
              {user ? <WaitingListPage /> : <LoginPage />}
            </Route>
            <Route path="/users/" exact>
              {user ? <UserGrowthPage /> : <LoginPage />}
            </Route>
          </Switch>
        </BrowserRouter>
      </UserContext.Provider>
    </ApolloProvider>
  )
};

App.propTypes = {
  
};

export default App;